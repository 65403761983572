
import React, { useState } from "react"
import PageInfo from "./PageInfo";

 export  default function AddVideo(params) {
    const [newVideo, setNewVideo] = useState({
       
        title: '',
        description: '',
       
        
      });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewVideo({ ...newVideo, [name]: value });
      };
    
      const handleAddVideo = () => {
       
        
        setNewVideo({
        
          
          title: '',
       
         
          thumbnailUrl: '',
        
        });
     
      };
    
    return(
        <>
<PageInfo title ="Add New Video" active="Add Video" from="Youtube Videos" parentpath="/dashboard/videos" />

         <div className='addvid'>
      
    

      <div className='add-video-section'>
        <h2>Add New Video</h2>

       
        <input
          type="text"
          name="title"
          placeholder="Title"
          value={newVideo.title}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="description"
          placeholder="Description"
          value={newVideo.description}
          onChange={handleInputChange}
        />
       
        <input
          type="text"
          name="thumbnailUrl"
          placeholder="Thumbnail URL"
          value={newVideo.thumbnailUrl}
          onChange={handleInputChange}
        />
    
        <button >
          <i className="fa fa-plus"></i> Add Video
        </button>
      </div>
</div>
        
        </>
    )
}