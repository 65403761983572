import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "./loadingspinner";
import Pagination from "./Pagination"; // Import the Pagination component
import SendMailPage from "./sendMailPage";

const Subscriberlist = (props) => {
  const navigate = useNavigate();

  const [sendOption, setSendOption] = useState("all");
  const [emailList, setEmailList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10;

  const handleOptionChange = (event) => {
    setSendOption(event.target.value);
  };

  const handleClick = (e) => {
    setEmailList((prevList) => {
      return [...prevList, e.target.name];
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const allEmails = props.data.map((item) => {
    return item.email;
  });
  const data = sendOption === "all" ? allEmails : emailList;
  const emailObject = { emaillist: data  , url: 'https://api.achillesdrill.com/newsletter/mailbox' , from : '/dashboard/subscribers'};
  // Pagination logic
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = props.data.slice(indexOfFirstEntry, indexOfLastEntry);

  return (
    <>
      <table className="data-table">
        <thead>
          <tr>
            
            <th className="bg-[#274CB2]">S/N</th>
            <th className="bg-[#274CB2]">Sender</th>
            <th className="bg-[#274CB2]">Email</th>
          </tr>
        </thead>
        <tbody>
          {currentEntries.map((item) => (
            <tr key={item.email}>
             
              <td className="text-center">{props.data.indexOf(item) + 1}</td>
              <td>{item.fullname}</td>
              <td>{item.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalEntries={props.data.length}
        entriesPerPage={entriesPerPage}
        onPageChange={handlePageChange}
      />
      <div className="bulk-actions my-5">
        <h3 className="mb-3">BULK ACTIONS</h3>
        <div className="flex flex-col lg:flex-row gap-2">
          <div className="bg-[#274CB2] text-center py-2 px-4 rounded-md">
            <label className=" text-white py-2 px-4 hover:cursor-pointer">
              <input
                type="radio"
                value="all"
                checked={sendOption === "all"}
                onChange={handleOptionChange}
              />
              <span className="text-[#274CB2]">__</span>Send to All
            </label>
          </div>
      
          <Link to="/dashboard/send-mail" state={emailObject}>
            <button className="bg-[#274CB2] bg-opacity-5 hover:bg-opacity-15 px-4 py-2 text-gray-900 rounded-md shadow">
              Send Mail
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Subscriberlist;
