import React, { useContext } from "react";
import Adminlist from "./adminlist";
import PageInfo from "./PageInfo";
import AddButton from "./AddButton";
import { AuthContext } from "./AuthContext";
const AdminPage =() =>{
    const { userDetails } = useContext(AuthContext);
    const isAdmin = userDetails.role === "Super-Admin"? true : false;
    if (!isAdmin){
        return ("Restricted")
    }
    return(
        <>
        <PageInfo title="Admins" active ="Admins" />

        <table className="data-table">
            <thead>
                <tr>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Role</td>                   
                    <td>Actions</td>
                </tr>
            </thead>
            <tbody>
                <Adminlist />
            </tbody>
        </table>
        <AddButton to="/dashboard/admins/add" item="Admin" />
        </>
    )
}
export default AdminPage