import React, { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import Modal from "./Modal";
const Adminrow = (props) => {
  const [reload , setReload] = useState(false)
  const [loading , setloading] = useState(false)
  const [displayModal,setDisplaymodal] = useState(false)
  const closeModal = () =>{
    setDisplaymodal(false)
    window.location.reload(); // Reload the page on successful deletion

  }
  const [isSuccess, setIsSucces] = useState(true)
  const [responseMessage, setResponseMessage] = useState('')
  const handleReload = () =>{
    setReload(!reload)
  }
   const removeAdmin = (id) => {
   setloading(true)
    const body = {
      _id: id,
    };
  
    fetch("https://api.achillesdrill.com/admin/remove", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        // Reload the page on successful deletion
          
        } else if (response.status === 500) {
          throw new Error("Internal Server Error");
        }
      })
      .then((data) => {
        setResponseMessage(data.message)
        setIsSucces(true);
        setloading(false)
        setDisplaymodal(true)
      
// Reload the page on successful deletion
      })
      .catch((error) => {
        console.error("Error deleting Admin:", error);
      });
  };
  
    return (
      <>

      <tr>
        
        <td>{props.name}</td>
        <td>{props.email}</td> 
        
        <td>{props.level}</td>   
       
        
      <td className="text-center">
      <button 
       disabled={loading} 
      className="bg-[#274CB2] px-3 py-2 mt-2 text-white text-sm rounded-md border-[#274CB2] mr-2" 
      onClick={() => removeAdmin(props.id)}>  
       {loading ? <i className="fa fa-spinner fa-spin"></i> : <FaTrashAlt />} 
      </button>
                
              </td>
      </tr>
      {displayModal && (
        <Modal
          closeModal={closeModal} 
          isSuccess={isSuccess} 
          responseMessage={responseMessage} 
        />
      )}
  
    </>
    );
  };
  
  export default Adminrow;