import { Outlet, Link } from "react-router-dom";
import Sidebar from "./sidebar";
import "./sidebar.css";
import Navbar from "./navbar";
import Footer from "./footer";
const Layout = () => {
  return (
    <>
      <div className='body'>
        <div className='hidden lg:block'>
          <Sidebar />
        </div>

        <div className='flex-1  relative lg:ml-[250px]'>
          <Navbar />
          <div className="lg:mt-10 mt-14"></div>
         <div className="p-5"> <Outlet  /></div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
