import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './sendMailPage.css';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const SendMailPage = () => {
  const location = useLocation();
  const { emaillist } = location.state || { emaillist: [] };
  const {url} = location.state || {url : ''}
  const {from} = location.state || {from : '/dashboard'}
  const {editable} = location.state || {editable : false}
  const navigate = useNavigate();

  const [mailTitle, setMailTitle] = useState('');
  const [mailBody, setMailBody] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const editorRef = useRef(null);
  const quillRef = useRef(null);
  const isInitializedRef = useRef(false); // Added ref to track initialization
  const [editablelist, setEditablelist] = useState('')
  const handleInputChange = (e) =>{
     setEditablelist(e.target.value)
  }
  useEffect(() => {
    if (!isInitializedRef.current) {
      const quill = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
          ]
        }
      });
      quillRef.current = quill;

      quill.on('text-change', () => {
        setMailBody(quill.root.innerHTML);
      });

      const handleKeyDown = (e) => {
        if (e.key === 'Enter' && quill.getText().trim() === '') {
          quill.clipboard.dangerouslyPasteHTML(quill.getSelection().index, '<p><br></p>');
          e.preventDefault();
        }
      };

      quill.root.addEventListener('keydown', handleKeyDown);

      isInitializedRef.current = true; // Mark as initialized
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true); // Start spinner
    const mailData = {
      subject: mailTitle,
      html: mailBody
    };
    const editableMailData = {
      email : editablelist.split(','),
      subject: mailTitle,
      html: mailBody
    };
    // https://api.achillesdrill.com//newsletter/mailbox
    fetch(url, {
      method: editable ? "POST" : "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(editable ? editableMailData : mailData )
    })
    .then(response => {
      setIsLoading(false); // Stop spinner
      if (response.status === 200) {
        console.log("Mail sent successfully!");
        navigate(from);
      } else {
        throw new Error("Failed to send mail");
      }
    })
    .catch(error => {
      setIsLoading(false); // Stop spinner in case of error
      console.error("Error sending mail:", error);
    });
  };

  const displayEmailList = !emaillist ? false : emaillist.length > 6 ? emaillist.slice(0, 6) : emaillist;

  return (
    <div className="send-mail-container">
      <p>To: </p>
    { editable ? <textarea  value={editablelist} onChange={handleInputChange} className='border w-full p-2' placeholder='Enter the email list separated with commas e.g johndoe@gmail.com , saphore12@gmail.com'/>

     : (<div className='border rounded-md p-4 lg:p-8 text-sm mt-2 mb-2 border-box w-full text-center'>
        {displayEmailList.join(', ')}
        <b> {emaillist.length > 6 && `, and ${emaillist.length - 6} more`}</b>
      </div>)}
      <h2>Send Mail</h2>
     
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={mailTitle}
            onChange={(e) => setMailTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="body">Body</label>
          <div className="border rounded-md">
            <div ref={editorRef} className="editor"></div>
          </div>
        </div>
        <div className="form-actions">
          <button type="submit" className="btn btn-primary" disabled={isLoading}>
            {isLoading ? (
             <i className='fa fa-spinner fa-spin'></i>
            ) : (
              "Send"
            )}
          </button>
          <button type="button" className="btn btn-secondary" onClick={() => navigate('/dashboard/subscribers')}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default SendMailPage;
