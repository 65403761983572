// import React, { useContext, useState } from 'react';
// import './profileDropdown.css'; // Create a CSS file for styling
// import { AuthContext } from './AuthContext';

// export default function Navbar() {
//   const { logout } = useContext(AuthContext);

//     const [isOpen, setIsOpen] = useState(false);

//     const toggleMenu = () => {
//         setIsOpen(!isOpen);
//     };
//     const options = { year: 'numeric', month: 'long', day: 'numeric' };
// const currentDate = new Date().toLocaleDateString('en-US', options);

//     return (
//         <nav>
//             <div className="date">
//                 <p><i className="fa-solid fa-calendar"></i> {currentDate}</p>
//             </div>
//             <div className="profile">
//                 <img
//                     src="/images/profile.png"
//                     alt=""
//                     srcSet=""
//                     width="30px"
//                     height="30px"
//                     onClick={toggleMenu}
//                 />
//                 {isOpen && (
//                     <div className="dropdown-men">
//                         <ul>
//                             <li><button><i className="fa-solid fa-key"></i> Change Password</button></li>
//                             <li onClick={logout}><button><i className="fa-solid fa-sign-out-alt" ></i> Logout</button></li>
//                         </ul>
//                     </div>
//                 )}
//             </div>
//         </nav>
//     );
// }

import React, { useContext, useState } from "react";
import { AuthContext } from "./AuthContext";
import Sidebar from "./sidebar";
import { FaBars, FaHamburger } from "react-icons/fa";

export default function Navbar() {
  const { logout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  const [isMenuOpened, setOpenMenu] = useState(false);

  const openMenu = () => setOpenMenu(!isMenuOpened);
  const closeMenu = () => setOpenMenu(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const options = { year: "numeric", month: "long", day: "numeric" };
  const currentDate = new Date().toLocaleDateString("en-US", options);

  return (
    <>
      <nav className="lg:hidden top-0 z-20 fixed w-full shadow-md lg:px-3 lg:py-2 bg-[#274CB2] p-4 border-box  flex items-center lg:gap-10 lg:justify-start justify-between mb-4 text-[#274CB2]">
        <div className="w-full flex items-center justify-between">
          <FaBars
            className="text-white hover:cursor-pointer"
            onClick={openMenu}
          />
          <div className="flex items-center">
            <p className="text-sm flex items-center text-gray-100">
              <i className="fa-solid fa-calendar mr-2"></i> {currentDate}
            </p>
          </div>

          <div className="">
            <img
              src="/images/profile.png"
              alt="Profile"
              width="30"
              height="30"
              onClick={toggleMenu}
              className="cursor-pointer rounded-full"
            />

            {isOpen && (
              <div className="absolute  text-sm right-0 mt-2 w-48 bg-white text-gray-800 border border-gray-300 rounded shadow-lg z-10">
                <ul className="list-none p-2">
                  {/* <li className="hover:bg-gray-100">
                  <button className="w-full text-left px-4 py-2 flex items-center">
                    <i className="fa-solid fa-key mr-2"></i> Change Password
                  </button>
                </li> */}
                  <li className="hover:bg-gray-100">
                    <button
                      onClick={logout}
                      className="w-full text-left px-4 py-2 flex items-center"
                    >
                      <i className="fa-solid fa-sign-out-alt mr-2"></i> Logout
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        {isMenuOpened && <Sidebar closeMenu={closeMenu} />}
      </nav>
      {/* DESKTOP NAVBAR ===========================*/}
      <nav className="top-0 z-20 fixed w-full shadow-md  lg:py-2 bg-[#274CB2]  border-box  hidden lg:flex   mb-4 text-[#274CB2]">
        <div className="w-1/2 flex items-center justify-between relative  mx-auto  ">
          <div className="flex items-center">
            <p className="text-sm flex items-center text-gray-100">
              <i className="fa-solid fa-calendar mr-2"></i> {currentDate}
            </p>
          </div>

          <div className="">
            <img
              src="/images/profile.png"
              alt="Profile"
              width="30"
              height="30"
              onClick={toggleMenu}
              className="cursor-pointer rounded-full"
            />

            {isOpen && (
              <div className="absolute  text-sm right-0 mt-2 w-48 bg-white text-gray-800 border border-gray-300 rounded shadow-lg z-10">
                <ul className="list-none p-2">
                  {/* <li className="hover:bg-gray-100">
            <button className="w-full text-left px-4 py-2 flex items-center">
              <i className="fa-solid fa-key mr-2"></i> Change Password
            </button>
          </li> */}
                  <li className="hover:bg-gray-100">
                    <button
                      onClick={logout}
                      className="w-full text-left px-4 py-2 flex items-center"
                    >
                      <i className="fa-solid fa-sign-out-alt mr-2"></i> Logout
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </nav>{" "}
    </>
  );
}
