import React, { useEffect, useState } from "react";
import CommentList from "./commentlist";
import PageInfo from "./PageInfo";
import CommentItem from "./commentitem";
import Pagination from "./Pagination";
import Spinner from "./loadingspinner";

export default function CommentPage() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 10;
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  

    {

      //comment*	[...]
// article*	[...]
// visibility*	[...]
// fullname*	[...]
// created_at*
    }
    useEffect(() => {
      fetch("https://api.achillesdrill.com/comment/all")
        .then((response) => {
          if (!response.status === 200) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setData(data.allComment);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }, []);
  
    if (loading) {
      return <Spinner />;
    }
  
    if (error) {
      return <p>Error: {error.message}</p>;
    }
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = data.slice(indexOfFirstEntry, indexOfLastEntry);
    
    return(
        <>
        <PageInfo title="Comments" active ="Comments" />
        <table className="data-table">
      <thead>
        <tr>
          <th className="bg-[#274CB2]">Name</th>
          
          <th className="bg-[#274CB2]">Comment</th>

          <th className="bg-[#274CB2]">Status</th>
          <th className="bg-[#274CB2]">Post</th>
          <th className="bg-[#274CB2]">Posting Date</th>
          <th className="bg-[#274CB2]">Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => {
          return (
            <CommentItem
              key={item.id}
              name={item.fullname}
              
              comment={item.comment}
              status={item.visibility}
              post={item.article.title}
              postingdate={item.created_at}
              commentid={item._id}
            />
          );
        })}
      </tbody>
    </table>
    <Pagination
        currentPage={currentPage}
        totalEntries={data.length}
        entriesPerPage={entriesPerPage}
        onPageChange={handlePageChange}
      />
        </>
    )
}