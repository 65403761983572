import React from "react";
import "./sidebar.css";
import { Link } from "react-router-dom";

const AddButton = (props) => {
  return (
    <>
      <Link to={props.to}>
        <button className="add-item">
          <i className="fa fa-plus"></i> Add {props.item}
        </button>
      </Link>
    </>
  );
};

export default AddButton;
