import React from "react";
import BlogList from "./bloglists";
import PageInfo from "./PageInfo";
import { Link } from "react-router-dom";
export default function ManagePosts(props) {
  return (
    <>
      <PageInfo title='Manage Posts' active='Manage Posts' from='Posts' />

      <BlogList />
      <Link to='/dashboard/new-post' class='add-new' >
      
      <button className="w-32 bg-[#274CB2]  text-sm text-white py-2 rounded hover:bg-blue-600 transition duration-200 mt-2 "><i class='fa fa-plus'></i> Add New</button>
      </Link>
    </>
  );
}
