import React, { useState } from "react";
import "./blogmanager.css";
import PageInfo from "./PageInfo";
import Modal from "./Modal";

export default function BlogManager() {
  const [editorContent, setEditorContent] = useState("");
  const [postTitle, setPostTitle] = useState('');
  const [postCategory, setPostCategory] = useState('');
  const [conclusion , setConclusion] = useState('')
  const formatText = (command, value = null) => {
    document.execCommand(command, false, value);
  };
  
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
 
  
    const [imageBase64, setImageBase64] = useState('');

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
  
        reader.onloadend = () => {
          // Convert the image to base64 and update state
          setImageBase64(reader.result);
        };
  
        // Read the file as a data URL
        reader.readAsDataURL(file);
      }
    };
  
   
 
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [showModal, setShowModal] = useState(false);
  const saveArticle = (e) => {
    const content = document.getElementById("editor").innerHTML;
    setEditorContent(content);
    const payload = {
      
        image: imageBase64,
        category: postCategory,
        title: postTitle,
        content: content,
        conclusion: conclusion
      
    }
    e.preventDefault();
    setLoading(true);
    fetch("https://api.achillesdrill.com/blog/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(data.message || "Article Uploaded successfully!");
        setIsSuccess(true);
        setShowModal(true);
        setEditorContent("")
        setConclusion('')
        setPostTitle('')
      
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setResponseMessage("An error occurred. Please try again.");
        setIsSuccess(false);
        setShowModal(true);
        setLoading(false);
      });
  };

 
  
   const closeModal = () => {
    setShowModal(false);
  };;

  const insertImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        formatText("insertImage", event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleColorChange = (command, color) => {
    formatText(command, color);
  };

  return (
    <>
      <PageInfo title="New Post" active="New" from="Posts" />
      <div className="mx-auto lg:w-4/5">
      <div className="form-group">
      
      <input
        type="text"
        id="title"
        placeholder="Title"
        value={postTitle}
        onChange={(e) => setPostTitle(e.target.value)}
        required
      />
    </div>
    <div className="form-group">

    <input
            type="file"
            name="imageurl"
            placeholder="Featured Image"
            onChange={handleFileChange} 
            className="text-sm w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
         />
       
        </div>

          <div className="form-group">
           <input
        type="text"
        id="title"
        placeholder="Category"
        value={postCategory}
        onChange={(e) => setPostCategory(e.target.value)}
        required
      /></div>
          
      <div className="editor-container">
        <h1>Body</h1>
        <div className="toolbar">
          <button onClick={() => formatText("bold")}>
            <i className="fa fa-bold"></i>
          </button>
          <button onClick={() => formatText("italic")}>
            <i className="fa fa-italic"></i>
          </button>
          <button onClick={() => formatText("underline")}>
            <i className="fa fa-underline"></i>
          </button>
          <button onClick={() => formatText("strikethrough")}>
            <i className="fa fa-strikethrough"></i>
          </button>
          <button onClick={() => formatText("superscript")}>
            <i className="fa fa-superscript"></i>
          </button>
          <button onClick={() => formatText("subscript")}>
            <i className="fa fa-subscript"></i>
          </button>
          <button onClick={() => formatText("justifyLeft")}>
            <i className="fa fa-align-left"></i>
          </button>
          <button onClick={() => formatText("justifyCenter")}>
            <i className="fa fa-align-center"></i>
          </button>
          <button onClick={() => formatText("justifyRight")}>
            <i className="fa fa-align-right"></i>
          </button>
          <button onClick={() => formatText("justifyFull")}>
            <i className="fa fa-align-justify"></i>
          </button>
          <button onClick={() => formatText("insertOrderedList")}>
            <i className="fa fa-list-ol"></i>
          </button>
          <button onClick={() => formatText("insertUnorderedList")}>
            <i className="fa fa-list-ul"></i>
          </button>
          <button onClick={() => formatText("foreColor", "red")}>
            <i className="fa fa-font" style={{ color: "red" }}></i>
          </button>
          <button onClick={() => formatText("foreColor", "green")}>
            <i className="fa fa-font" style={{ color: "green" }}></i>
          </button>
          <button onClick={() => formatText("foreColor", "blue")}>
            <i className="fa fa-font" style={{ color: "blue" }}></i>
          </button>
          <button onClick={() => formatText("backColor", "yellow")}>
            <i className="fa fa-highlighter" style={{ color: "yellow" }}></i>
          </button>
          <button onClick={() => formatText("removeFormat")}>
            <i className="fa fa-eraser"></i>
          </button>
          <input
            type="file"
            onChange={insertImage}
            accept="image/*"
            style={{ display: "none" }}
            id="imageInput"
          />
          <button onClick={() => document.getElementById("imageInput").click()}>
            <i className="fa fa-image"></i>
          </button>
          <select onChange={(e) => formatText("fontSize", e.target.value)}>
            <option value="1">Small</option>
            <option value="3">Normal</option>
            <option value="5">Large</option>
            <option value="7">Huge</option>
          </select>
          <select onChange={(e) => formatText("fontName", e.target.value)}>
            <option value="Arial">Arial</option>
            <option value="Courier New">Courier New</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Verdana">Verdana</option>
          </select>
          <input
            type="color"
            onChange={(e) => handleColorChange("foreColor", e.target.value)}
            title="Text Color"
          />
          <input
            type="color"
            onChange={(e) => handleColorChange("backColor", e.target.value)}
            title="Background Color"
          />
          <button onClick={() => formatText("undo")}>
            <i className="fa fa-undo"></i>
          </button>
          <button onClick={() => formatText("redo")}>
            <i className="fa fa-redo"></i>
          </button>
        </div>
        <div
          id="editor"
          contentEditable={true}
          className="editor p-10"
          placeholder="Write your article here..."
        ></div>

        <textarea placeholder="Write your conclusion here..." className="text-sm w-full mb-4 mt-4 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-40" value={conclusion} onChange={(e) => setConclusion(e.target.value)}/>
            
            <button onClick={saveArticle} disabled={loading} type='button' className="w-full bg-[#274CB2] text-white py-2 rounded hover:bg-blue-600 transition duration-200 mt-2 "> {loading ? <i className="fa fa-spinner fa-spin"></i>: "Post Article"}</button>
   
      </div>
      
      </div>
      {showModal && (
        <Modal
          closeModal={closeModal}
          isSuccess={isSuccess}
          responseMessage={responseMessage}
        />
      )}
    </>
  );
}
