import React from "react";
import { Inboxlist } from "./inboxlist";
import PageInfo from "./PageInfo";
export default function Inboxpage() {
    return(
        <>
<PageInfo title ="Enquiries" active="Enquiries" from="Mail" />

        <Inboxlist />
        </>
    )
}