import React, { useState, useEffect } from "react";
import VideoList from "./videolist.js";
import { Link } from "react-router-dom";
import Spinner from "./loadingspinner.js";
import PageInfo from "./PageInfo.js";
import { FaTrashAlt } from "react-icons/fa";

const App = () => {
  const [numVideosToShow, setNumVideosToShow] = useState(4);
  const [videos, setVideos] = useState([]);
  const testVideos = {
    url: "https://www.youtube.com/embed/gOhGsrayF18?si=ZpzklCn8FV-iXi_2",
    title: "lorem",
    slot: "1",
  };
  const [newVideo, setNewVideo] = useState({
    url: "",
    title: "",
    slot: "",
  });

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [currentslot, setCurrentslot] = useState("");
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for loading spinner
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("https://api.achillesdrill.com/video/get")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setVideos(data.videos);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleNumVideosChange = (e) => {
    setNumVideosToShow(parseInt(e.target.value, 10));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVideo((prevNewVideo) => ({ ...prevNewVideo, [name]: value }));
  };

  const handleAddVideo = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start spinner

    const videoData = { ...newVideo };

    fetch("https://api.achillesdrill.com/video/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(videoData),
    })
      .then((response) => {
        setIsSubmitting(false); // Stop spinner
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 300) {
          console.log(response);
          throw new Error("The Video Already Exists/Failed to Update");
        } else if (response.status === 500) {
          throw new Error("Internal Server Error");
        }
      })
      .then((data) => {
        console.log(data);
        // Assuming data contains the added video
        setVideos((prevVideos) => [...prevVideos, { ...videoData }]);
        setNewVideo({
          url: "",
          title: "",
          slot: "",
        });
        setIsFormVisible(false);
      })
      .catch((error) => {
        setIsSubmitting(false); // Stop spinner in case of error
        console.error("Error adding video:", error);
      });
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const other_slots = ["2", "3", "4", "5"];

  const openModal = () => {
    setIsFormVisible(true);
  };

  const closeModal = () => {
    setIsFormVisible(false);
  };
  const firstvideo = videos.find((item) => item.slot === "1");
  const removeVideo = (id) => {
    const body = {
      _id: id,
    };

    fetch("https://api.achillesdrill.com/video/delete", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 500) {
          throw new Error("Internal Server Error");
        }
      })
      .then((data) => {
        console.log(data);
        // Assuming data contains the deleted video information
        setVideos((prevVideos) =>
          prevVideos.filter((video) => video._id !== id)
        );
      })
      .catch((error) => {
        console.error("Error deleting video:", error);
      });
  };

  return (
    <>
      <PageInfo title="Youtube Videos" active="Youtube Videos" />
      <div className="w-[90%] mx-auto mt-5 md:p-5 lg:w-[90%] xl:w-[100%]">
        <div className="flex flex-col justify-center w-full gap-2 md:flex-row">
          {/* <span className="flex items-center gap-2 text-gray-700">
              <i className="fa fa-plus-circle text-2xl"></i> Add Video
            </span> */}
          {firstvideo ? (
            <div className="relative block justify-center items-center h-64  rounded-lg border border-gray-300  md:w-1/3 md:h-auto md:flex-[5] ">
              <iframe
                className="h-full w-full"
                src={firstvideo.url}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>

              <button
                className="z-10 absolute top-0 right-0 bg-[#274CB2] text-white p-2 rounded-md flex items-center text-sm gap-1"
                onClick={() => removeVideo(firstvideo._id)}
              >
                <FaTrashAlt />
                Remove
              </button>
            </div>
          ) : (
            <div
              className="flex justify-center items-center h-64 bg-gray-200 rounded-lg border border-gray-300 hover:opacity-80  md:w-1/3 md:h-auto md:flex-[5]"
              onClick={() => {
                setNewVideo({
                  url: "",
                  title: "",
                  slot: "1",
                });
                openModal();
              }}
            >
              <span className="flex items-center gap-2 text-gray-700">
                <i className="fa fa-plus-circle text-2xl"></i> Add Video
              </span>
            </div>
          )}

          <div className="grid grid-cols-1 justify-center gap-2 w-full md:flex-[7]  md:grid-cols-2 md:gap-2">
            {other_slots.map((item) => {
              const vidItem = videos.find((video) => video.slot === item);
              if (vidItem) {
                return (
                  <div className="relative justify-center h-64 items-center  bg-gray-200 rounded-lg border border-gray-300 hover:opacity-80  md:h-64 ">
                    <iframe
                      className="h-full w-full"
                      src={vidItem.url}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>

                    <button
                      className="absolute z-10 top-4 right-3 bg-[#274CB2] text-white p-2 rounded-md flex items-center text-sm gap-1"
                      onClick={() => removeVideo(vidItem._id)}
                    >
                      <FaTrashAlt />
                    </button>
                  </div>
                );
              } else {
                return (
                  <div
                    className="flex justify-center items-center h-64   bg-gray-200 rounded-lg border border-gray-300 hover:opacity-80 lg:hover:scale-105 transition-transform duration-200 md:h-64"
                    onClick={() => {
                      setNewVideo({
                        url: "",
                        title: "",
                        slot: item,
                      });
                      openModal();
                    }}
                  >
                    <span
                      className="flex items-center gap-2 text-gray-700"
                      onClick={() => {
                        setNewVideo({
                          url: "",
                          title: "",
                          slot: item,
                        });
                        openModal();
                      }}
                    >
                      <i className="fa fa-plus-circle text-2xl"></i> Add Video
                    </span>
                  </div>
                );
              }
            })}

            {isFormVisible && (
              <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-screen">
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
                  <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-lg w-full">
                    <div className="bg-white pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <div className="mt-2">
                            <h2 className="text-[#274CB2] text-1xl text-center">
                              Add Video [slot {newVideo.slot}]
                            </h2>
                            <form onSubmit={handleAddVideo}>
                              <input
                                type="text"
                                id="url"
                                name="url"
                                onChange={handleInputChange}
                                value={newVideo.url}
                                placeholder="Embed URL"
                                required
                                className="mt-1 p-3 block w-full rounded-md border-2 mb-2"
                              />
                              <input
                                type="text"
                                id="title"
                                name="title"
                                onChange={handleInputChange}
                                value={newVideo.title}
                                placeholder="Title"
                                required
                                className="mt-1 p-3 block w-full rounded-md border-2 mb-2"
                              />
                              <input
                                type="text"
                                id="slot"
                                name="slot"
                                onChange={handleInputChange}
                                hidden
                                value={newVideo.slot}
                                placeholder="Slot"
                                required
                                className="mt-1 p-3  w-full rounded-md border-2 mb-2"
                              />
                              <div className="mt-4">
                                <button
                                  type="submit"
                                  className="bg-[#274CB2] text-white  py-2 px-4 rounded flex items-center justify-center"
                                >
                                  {isSubmitting ? (
                                    <svg
                                      className="animate-spin h-5 w-5 text-white"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                      ></circle>
                                      <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8v8z"
                                      ></path>
                                    </svg>
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
