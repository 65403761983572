import React, { useState, useEffect } from "react";
import Adminrow from "./adminrow";
import Spinner from "./loadingspinner";

const Adminlist = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("https://api.achillesdrill.com/admin/all")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.admins); // Adjust according to the structure of the response
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return(

        <Spinner />
    ); // You can replace this with a spinner component
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
   <>
      {data.map((item) => (
        <Adminrow
          id = {item._id}
          key={item._id}
          name={item.fullname}
          email={item.email}
          level={item.role}
          phonenumber={item.phonenumber}
        />
      ))}
    </>
  );
};

export default Adminlist;
