// import React, { useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { AuthContext } from "./AuthContext";
// import "./login.css";

// export default function Login() {
//   const { isAuthenticated } = useContext(AuthContext);

//   const { login } = useContext(AuthContext);
//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//   });
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();
//   if (isAuthenticated === true) {
//     navigate("/dashboard");
//   }
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);

//     // Perform login logic (e.g., API call to your backend)
//     const response = await fetch("https://api.achillesdrill.com/admin/login", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(formData),
//     });

//     setIsLoading(false);

//     if (response.status === 200) {
//       const data = await response.json();
//       // Pass the token to the login function from AuthContext
//       login(data.access_token);
//       navigate("/dashboard");
//     } else if (response.status === 300) {
//       const data = await response.json();
//       setIsLoading(false);
//       // Handle login error
//       alert(data.message);
//     } else {
//       setIsLoading(false);
//       alert("Login Failed");
//     }
//   };

//   return (
//     <>
//       <div className='containerlog'>
//         <div className='login-container'>
//           <div className='login-image'>
//             <img src='/images/login.jpg' alt='' />
//           </div>

//           <div className='login-form'>
//             <img src='/images/achelis.png' alt='' />

//             <h2>Welcome back!</h2>
//             <p>Enter your details to login</p>
//             <form onSubmit={handleSubmit}>
//               <input
//                 type='email'
//                 name='email'
//                 placeholder='Email'
//                 value={formData.email}
//                 onChange={handleChange}
//               />
//               <input
//                 type='password'
//                 name='password'
//                 placeholder='Password'
//                 value={formData.password}
//                 onChange={handleChange}
//               />
//               <button type='submit' className='login-button' disabled={isLoading}>
//                 {isLoading ? <span className='spinner'></span> : "Login"}
//               </button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import "./login.css";

export default function Login() {
  const { isAuthenticated } = useContext(AuthContext);

  const { login } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  if (isAuthenticated === true) {
    navigate("/dashboard");
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Perform login logic (e.g., API call to your backend)
    const response = await fetch(
      "https://api.achillesdrill.com/admin/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    setIsLoading(false);

    if (response.status === 200) {
      const data = await response.json();
      // Pass the token to the login function from AuthContext
      login(data.access_token);
      navigate("/dashboard");
    } else if (response.status === 300) {
      const data = await response.json();
      setIsLoading(false);
      // Handle login error
      alert(data.message);
    } else {
      setIsLoading(false);
      alert("Login Failed");
    }
  };

  return (
    <>
      <div className="mt-12 mb-8 bg-cover bg-center w-[90%] mx-auto pt-4 px-2 pb-2 border-2 border-[#274CB2] sm:w-[75%] md:px-6 xl:w-[60%]">
        <div>
          <img
            src="/images/achelis.png"
            alt=""
            className="mb-1 mx-auto md:hidden"
          />
        </div>

        <div className="bg-white pt-4 w-full border border-white md:pb-4 md:flex items-stretch">
          <div className="flex-1">
            <img
              src="/images/login.jpg"
              alt=""
              className="w-full h-64 sm:h-72 md:h-full xl:h-96"
            />
          </div>

          <div className="px-5 pb-5 pt-3 box-border flex-1 sm:px-7 md:px-0 md:py-0">
            <img
              src="/images/achelis.png"
              alt=""
              className="mb-4 hidden md:block"
            />

            <h2 className="text-xl mb-2 md:text-2xl">Welcome back!</h2>

            <p className="mb-4 md:text-xl">Enter your details to login</p>

            <form onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="p-2 border-2 border-[#274CB2] rounded w-full block mb-6 outline-transparent xl:w-4/5"
              />

              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                className="p-2 border-2 border-[#274CB2] rounded w-full block mb-6 outline-transparent xl:w-4/5"
              />

              <button
                type="submit"
                className="text-base rounded w-24 h-9 bg-[#274CB2] border border-[#274CB2] text-white hover:bg-blue-700 relative flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? <span className="spinner h-full"></span> : "Login"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
