import React from "react";
import QuestionList from "./questionlist";
import PageInfo from "./PageInfo";
import {useNavigate } from "react-router-dom";
import AddButton from "./AddButton";
export default function FaqsManager() {
    const navigate =  useNavigate();
    function goToNew() {
        navigate("./new");
    }
    
    return(
        <>
        <PageInfo title="FAQs" active ="FAQs" />

        <QuestionList />
        <AddButton  to="/dashboard/faqsmanager/new" item="Question"/>
       
        </>
    )
}