import React from "react";
import { NavLink } from "react-router-dom";
const PageInfo = (props) => {
  return (
    <div className='flex items-center justify-between m-0 mb-3'>
      <h3 className='text-sm font-semibold text-gray-700 md:text-sm'>{props.title}</h3>

      <h3 className='page-path text-sm'>
        Dashboard /{" "}
        {props.from ? (
          <NavLink to={props.parentpath ? props.parentpath : ""}> {props.from} / </NavLink>
        ) : (
          ""
        )}
        <span className='active'>{props.active} /</span>
      </h3>
    </div>
  );
};
export default PageInfo;
