// import React from "react";
// import "./video.css";

// const Video = ({ video }) => {
//   const videoUrl = `https://www.youtube.com/watch?v=${video.videoId}`;

//   return (
//     <div className='video-item'>
//       <div className='con'>
//         <a href={videoUrl} target='_blank' rel='noopener noreferrer' className='video-thumbnail'>
//           <img src={video.thumbnailUrl} alt={video.title} />
//           <i className='fas fa-play-circle play-icon'></i>
//         </a>
//         <div className='video-info'>
//           <a href={videoUrl} target='_blank' rel='noopener noreferrer' className='video-title-link'>
//             <h3 className='video-title'>{video.title}</h3>
//           </a>
//           {/* <p className="video-description">{video.description}</p> */}
//           <div className='video-meta'>
//             <span className='video-views'>
//               <i className='fas fa-eye'></i> {video.views}
//             </span>
//             <span className='video-date'>
//               <i className='fas fa-clock'></i> {new Date(video.datePosted).toLocaleDateString()}
//             </span>
//           </div>
//         </div>
//         <div>
//           <div className='actions'>
//             <span className='replace'>
//               <img src='/images/exchange.png' alt='' width='13px' height='13px'></img>
//               Replace
//             </span>
//             <span className='edit'>
//               <img src='/images/write.png' alt='' width='13px' height='13px'></img>
//               Edit
//             </span>
//             <span className='edit'>
//               {" "}
//               <i class='fa fa-trash removevid'></i>Trash
//             </span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Video;

import React from "react";

const Video = ({ video }) => {
  const videoUrl = `https://www.youtube.com/watch?v=${video.videoId}`;

  return (
    <div className='flex flex-col w-full max-w-xs mx-auto rounded-lg overflow-hidden bg-white transition-transform duration-200 mb-6 pb-1 hover:transform hover:translate-y-[-5px] hover:shadow-lg'>
      <div className='relative'>
        <a
          href={videoUrl}
          target='_blank'
          rel='noopener noreferrer'
          className='block w-full h-auto overflow-hidden relative'
        >
          <img src={video.thumbnailUrl} alt={video.title} className='w-full h-32 object-contain' />
          <i className='fas fa-play-circle absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl text-white opacity-80'></i>
        </a>

        <div className='p-3 text-left'>
          <a
            href={videoUrl}
            target='_blank'
            rel='noopener noreferrer'
            className='no-underline text-black'
          >
            <h3 className='m-0 text-sm font-medium leading-tight text-gray-900 transition-colors duration-200 hover:text-blue-600'>
              {video.title}
            </h3>
          </a>

          {/* <p className="text-xs text-gray-600 leading-tight mt-2">{video.description}</p> */}
          <div className='flex justify-between items-center text-xs text-gray-600 mt-2'>
            <span className='flex items-center'>
              <i className='fas fa-eye mr-1'></i> {video.views}
            </span>

            <span className='flex items-center text-gray-500'>
              <i className='fas fa-clock mr-1'></i>{" "}
              {new Date(video.datePosted).toLocaleDateString()}
            </span>
          </div>
        </div>

        <div className='flex items-center gap-2 p-2'>
          <span className='flex items-center text-xs cursor-pointer gap-1 hover:text-blue-600'>
            <img src='/images/exchange.png' alt='' width='13' height='13' /> Replace
          </span>

          <span className='flex items-center text-xs cursor-pointer gap-1 hover:text-blue-600'>
            <img src='/images/write.png' alt='' width='13' height='13' /> Edit
          </span>

          <span className='flex items-center text-xs cursor-pointer gap-1 hover:text-blue-600'>
            <i className='fa fa-trash text-xs'></i> Trash
          </span>
        </div>
      </div>
    </div>
  );
};

export default Video;
