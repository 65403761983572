import React, { useEffect, useState } from "react";
import OutboxItem from "./outboxitem";
import PageInfo from "./PageInfo";
import Spinner from "./loadingspinner";
import Pagination from "./Pagination";
import { formatDistanceToNow } from "date-fns";
const OutboxPage = () =>{

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 10;
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
const formatRelativeTime = (date) => {
        const parsedDate = new Date(date);
      
        if (isNaN(parsedDate.getTime())) {
          throw new RangeError("Invalid time value");
        }
        return formatDistanceToNow(new Date(date), { addSuffix: true });
      };
    {

      //comment*	[...]
// article*	[...]
// visibility*	[...]
// fullname*	[...]
// created_at*
    }
    useEffect(() => {
      fetch("https://api.achillesdrill.com/outbox/read")
        .then((response) => {
          if (!response.status === 200) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setData(data.outbox);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }, []);
  
    if (loading) {
      return <Spinner />;
    }
  
    if (error) {
      return <p>Error: {error.message}</p>;
    }
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = data.slice(indexOfFirstEntry, indexOfLastEntry);
    return(
        <>
        <PageInfo title="Outbox" active ="Outbox" from="Mail" />
        {
            data.map((item) =>{
                return(
                    <OutboxItem  subject={item.subject} recipient ={item.recipient} body={item.body} date={formatRelativeTime(item.date)} />

                )
            })
        }
     
        <Pagination
        currentPage={currentPage}
        totalEntries={data.length}
        entriesPerPage={entriesPerPage}
        onPageChange={handlePageChange}
      />
        </>
    )
}
export default OutboxPage