

import { useContext, useEffect, useState } from "react";
import Sidebar from "./sidebar";
import { AuthContext } from "./AuthContext";
import { FaBell, FaBook, FaEnvelope, FaInbox, FaSpinner, FaYoutube } from "react-icons/fa";
import { FaBookmark, FaMessage } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from 'date-fns';

export default function Dashboard() {
  const { userDetails } = useContext(AuthContext);
  const [data, setData]= useState(
    {}
  )
  const [error, setError] = useState('')
  const [loading,setLoading] = useState(true)
 useEffect(() => {
  setLoading(true)
  fetch("https://api.achillesdrill.com/admin/counter/")
    .then((response) => {
      if (!response.status === 200) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      setData(data);
      setLoading(false);
    })
    .catch((error) => {
      setError(error);
      setLoading(false);
    });
}, []);

const [blogs,setBlogs] = useState([])
  const [blogError , setBlogError] = useState('')
  useEffect(() => {
    fetch("https://api.achillesdrill.com/blog/fetch")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setBlogs(data.articles);
        console.log(data.articles)
        
      })
      .catch((error) => {
        setBlogError(error);
        
      });
  }, []);

if (error) {
  return <p>Error: {error.message}</p>;
}
  // The empty array ensures this runs only once on mount
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) {
      return 'Good morning!';
    } else if (hour < 16) {
      return 'Good afternoon!';
    } else {
      return 'Good evening!';
    }
  };
  const formatRelativeTime = (date) => {
    const parsedDate = new Date(date);
  
    if (isNaN(parsedDate.getTime())) {
      throw new RangeError("Invalid time value");
    }
    return formatDistanceToNow(new Date(date), { addSuffix: true });
  };

  

  const getLatestPosts = (posts, numberOfPosts) => {
    
    // Sort posts by created_at in descending order
    const sortedPosts = posts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    
    // Return the latest posts based on the numberOfPosts argument
    return sortedPosts.slice(0, numberOfPosts);
  };
  const latestPosts = getLatestPosts(blogs, 6)
  return (
    <div className="flex">
      <div className="hidden lg:block">
        <Sidebar />
      </div>

      <div className="flex-1 p-2">
        <div className="mb-6 text-center">
          <h1 className="text-2xl text-[#274CB2]">
            Welcome Back {userDetails.fullname}!
            <span role="img" aria-label="wave">
              👋
            </span>
          </h1>
          
          <p className="text-gray-600">{getGreeting()}</p>
        </div>
      
        <div className="grid grid-cols-1  gap-6 mb-6 md:grid-cols-3 w-full ">
          <div className="relative bg-gray-50 shadow-md rounded-lg p-6 flex items-center hover:scale-105 hover:border-b-purple-600 hover:border-b-2 hover:transition-all duration-200">
         {
          loading ? 
            <i className="fa fa-spinner fa-spin "></i>
            :
            <>

<FaBookmark className="text-4xl mr-4 text-[#274CB2]" />
            <div>
              <h3 className="text-sm font-semibold"> Posts</h3>
              <p className="text-sm font-bold text-purple-600">{data.blog}</p>
              <h3 className="text-[0.75em] font-semibold relative flex items-center">
  
  
  {data.comments && data.comments.approved > 0 ? (
    <>
            Comments
            <sup className="bg-red-700 rounded-full w-4 h-4 text-white text-[0.77em] flex items-center justify-center">
              {data.comments.approved}
            </sup></>
          ) : (
            " "
          )}
</h3>
 


              
            </div>
            </>

}
            
          </div>
          <Link to='./subscribers'><div className="relative bg-gray-50 shadow-md rounded-lg p-6 flex items-center hover:scale-105 hover:border-b-green-600 hover:border-b-2 transition-all duration-200">
          
          {
          loading ? 
            <i className="fa fa-spinner fa-spin "></i>
            :
            <>

         
          <span className="relative mr-4 p-2"><FaBell className="text-4xl  text-[#274CB2]" /> </span>

<div>
  <h3 className="text-sm font-semibold">Subscribers</h3>
  <p className="text-sm font-bold text-green-600">{data.subscribers}</p>
</div>

            </>

}
          
          </div></Link>


          <Link to='./inbox'> <div className="relative bg-gray-50 shadow-md rounded-lg p-6 flex items-center hover:scale-105 hover:border-b-red-600 hover:border-b-2 hover:transition-all duration-200">
           {
            loading ?
            <i className="fa fa-spinner fa-spin "></i>
            :
            <>
             {data.enquiries  ? (
              <>
           <span className="relative mr-4 p-2"> <FaEnvelope className="text-4xl text-[#274CB2]" />{data.enquiries.active === 0 ? " " : <span className="text-white text-sm text-center font-bold absolute top-0 right-0 bg-red-600 rounded-full border  w-5 h-5">{data.enquiries.active}</span>}</span>

            <div>
              <h3 className="text-sm font-semibold">Enquiries</h3>
              <p className="text-sm font-bold text-red-600">{data.enquiries.active + data.enquiries.inactive}</p>
            </div>
            </>
             ) : ""
            }
            </>

           }
          </div>
</Link>
      
        </div>

        <div className="bg-gray-50 shadow rounded-lg p-3 pb-5 mx-auto">
          <h2 className="text-sm mb-4 flex items-center justify-between">
            Recent Blogs
            <Link to="./new-post"><button
             
              className="text-[#274CB2] text-sm border border-[#274CB2] p-2 rounded flex items-center hover:bg-[#274CB2] hover:text-white"
            >
              <i className="fa fa-plus mr-1"></i> Add New
            </button></Link>
          </h2>

          <ul className="grid gap-5 grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
            {latestPosts.map((blog, index) => (
              <li
                key={index}
                className="bg-white shadow-md rounded-lg p-4 md:flex items-center hover:scale-y-105 transition-all duration-200"
              >
                <img
                  src={blog.image}
                  alt="Blog"
                  className="w-full h-24 mb-2 object-cover rounded md:h-16 md:w-24 md:mr-4 lg:mb-0"
                />
                <div className="flex-1 text-center md:text-left">
                  <h4 className="text-[16px] font-semibold mb-2 lg:mb-0">
                    {blog.title}
                  </h4>
                  <div className="text-gray-600 flex gap-2 md:gap-4">
                    <span className="text-xs text-[#274CB2]">
                      <i className="fa fa-comments mr-1"></i> {blog.comments.length}
                    </span>
                    {/* <span className="text-xs text-red-600">
                      <i className="fa fa-eye mr-1"></i> {blog.views}
                    </span> */}
                    <span className="text-xs text-[#274CB2]">
                      <i className="fa fa-clock mr-1"></i> {formatRelativeTime(blog.created_at)}
                    </span>
                  </div>
                </div>

                
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
