import React, { useState, useEffect } from "react";

import Spinner from "./loadingspinner";
import InboxItem from "./inboxitem";
import Pagination from "./Pagination";

export function Inboxlist() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 6;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetch("https://api.achillesdrill.com/contact/enquiries")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.enquiries);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = data.slice(indexOfFirstEntry, indexOfLastEntry);
  return (
    <>
      <table className="data-table">
        <thead className="bg-[#274CB2]">
          <tr>
            <th className="bg-[#274CB2]">Email</th>
            <th className="bg-[#274CB2]">Status</th>
            <th className="bg-[#274CB2]">Sender</th>
            <th className="bg-[#274CB2]">S/N</th>
            <th className="bg-[#274CB2]">Subject</th>
            <th className="bg-[#274CB2]">View Message</th>
            <th className="bg-[#274CB2]">Date</th>
            <th className="bg-[#274CB2]">School</th>
            <th className="bg-[#274CB2]">Send Mail</th>
          </tr>
        </thead>
        <tbody>
          {currentEntries.map((item) => (
            <InboxItem
              serialnum={data.indexOf(item) + 1}
              key={item._id}
              senderName={`${item.fname} ${item.lname}`}
              email={item.email}
              status = {item.active}
              subject={item.subject}
              messageid={item._id}
              body={item.enquiry}
              date={item.created_at}
              school={item.school}
            />
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalEntries={data.length}
        entriesPerPage={entriesPerPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}
