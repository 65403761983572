import React, { useState, useRef } from "react";
import { FaCalendarAlt, FaChevronCircleDown, FaChevronCircleUp, FaRegEnvelope } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import './outboxitem.css';

const OutboxItem = (props) => {
  const displayEmailList = !props.recipient ? false : props.recipient.length > 2 ? props.recipient.slice(0, 2) : props.recipient;

  const [viewContent, setViewContent] = useState(false);
  const contentRef = useRef(null);

  const toggleContent = () => {
    setViewContent(!viewContent);
  };

  return (
    <div className="block shadow-sm mb-2 ">
      <div className="flex border border-white p-3 justify-between text-sm font-normal items-center ">
        <div className="block w-1/3">
          <h2 className="font-bold flex items-center text-[#274CB2]">
            <FaRegEnvelope className="mr-2" />
            Subject
          </h2>
          <h3 className="font-light">{props.subject}</h3>
        </div>
        <div className="block  w-1/3">
          <h2 className="font-bold flex items-center text-[#274CB2] mx-auto text-center">
            <FaPeopleGroup className="mr-2" />
            To
          </h2>
          <h3 className=" font-light text-sm">
          {displayEmailList.join(', ')}
          <b> {props.recipient.length > 2 && `, and ${props.recipient.length - 2} more`}</b>
          </h3>
        </div>
        <div className="block">
          <h2 className="font-bold flex items-center text-[#274CB2]">
            <FaCalendarAlt className="mr-2" />
            Date
          </h2>
          <h3 className="font-normal">{props.date}</h3>
        </div>
        <div className="block font-normal">
          {!viewContent ? (
            <FaChevronCircleDown className="text-[1.1rem] cursor-pointer" onClick={toggleContent} />
          ) : (
            <FaChevronCircleUp className="text-[1.1rem] cursor-pointer" onClick={toggleContent} />
          )}
        </div>
      </div>
      <div
        className={`mailcontent bg-gray-100 p-1 transition-all duration-500 ${viewContent ? "p-4 open" : "close"}`}
        ref={contentRef}
      >
        {/* Content goes here */}
        <div dangerouslySetInnerHTML={{__html: props.body}}>

        </div>
        
      </div>
    </div>
  );
};

export default OutboxItem;
