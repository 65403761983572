import React from "react";
import "./modal.css"; // Import the CSS file
import { IoCheckmarkCircleOutline } from "react-icons/io5";

const Modal = ({ closeModal, isSuccess, responseMessage }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={closeModal} className="close-button">
          <i className="fa fa-times"></i>
        </button>
        <div className="flex flex-col items-center gap-5">
          <p className={`font-semibold text-8xl text-[#274CB2]`}>
            {isSuccess === null ? <IoCheckmarkCircleOutline /> : ""}
          </p>
          <p className={`font-semibold text-2xl`}>
            {isSuccess === null ? "Congrats!" : ""}
          </p>
        </div>
        <div
          className={` ${
            isSuccess === null
              ? " "
              : isSuccess
              ? "icon-wrapper success"
              : " icon-wrapper error"
          }`}
        >
          <i
            className={`fa ${
              isSuccess === null
                ? ""
                : isSuccess
                ? "fa-check-circle"
                : "fa-times-circle"
            } icon`}
          ></i>
        </div>
        <p
          className={`message ${
            isSuccess === null
              ? "normal text-sm text-left"
              : isSuccess
              ? "success"
              : "error"
          } `}
        >
          {responseMessage}
        </p>
        <button
          onClick={closeModal}
          className={`modal-button ${
            isSuccess === null
              ? "message mt-6"
              : isSuccess
              ? "success"
              : "error"
          }`}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
