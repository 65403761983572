import React, { useState } from "react";
import Modal from "./Modal";
import { FaEnvelopeOpen } from "react-icons/fa";
import { TiArrowForward } from "react-icons/ti";

const InboxItem = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <tr>
        <td>{props.serialnum}</td>
        <td>{props.senderName}</td>
        <td>{props.email}</td>
        <td>{props.status ? "Active" : "Not Active"}</td>
        <td>{props.subject}</td>

        <td>
          <button
            onClick={openModal}
            className="button text-[#274CB2] flex flex-col"
          >
            <div className="mx-auto text-2xl">
              <FaEnvelopeOpen className="transform transition-transform duration-300 ease-in-out hover:-translate-y-2" />
            </div>
          </button>
        </td>
        <td>{new Date(props.date).toLocaleDateString()}</td>
        <td>{props.school}</td>
        <td>
          <div className="button flex flex-col text-[#274CB2]">
            <a className="mx-auto text-4xl" href={`message/${props.messageid}`}>
              <TiArrowForward className="transform transition-transform duration-300 ease-in-out hover:-translate-y-2" />
            </a>
          </div>
        </td>
      </tr>
      {isModalOpen && (
        <Modal
          closeModal={closeModal}
          isSuccess={null}
          responseMessage={props.body}
        />
      )}
    </>
  );
};

export default InboxItem;
