// import React, { useContext, useState } from "react";
// import { NavLink, useLocation } from "react-router-dom";
// import "./sidebar.css"; // Make sure to import your CSS
// import { AuthContext } from "./AuthContext";

// export default function Sidebar() {
//   const { isAuthenticated } = useContext(AuthContext);

//   const [isPostsDropdownOpen, setIsPostsDropdownOpen] = useState(false);
//   const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);
//   const [isMailDropdownOpen, setIsMailDropdownOpen] = useState(false);
//   const { userDetails } = useContext(AuthContext);

//   const togglePostsDropdown = () => setIsPostsDropdownOpen(!isPostsDropdownOpen);
//   const toggleProductDropdown = () => setIsProductDropdownOpen(!isProductDropdownOpen);
//   const toggleMailDropdown = () => setIsMailDropdownOpen(!isMailDropdownOpen);
//   if (!isAuthenticated) {
//     return "";
//   }
//   const isAdmin = userDetails.role === "Super-Admin" ? true : false;

//   return (
//     <div className='sidebar'>
//       <img src='/images/achelis.png' alt='Logo' />
//       <h3 className='heading'>ADMIN PANEL</h3>
//       <ul className='sidebar-menu'>
//         <li className='first'>
//           <NavLink to='/dashboard' exact activeClassName='active'>
//             <img src='/images/dashboard.png' width='18px' alt='Dashboard' />
//             <span className='sidebar-texts'>Dashboard</span>
//           </NavLink>
//         </li>
//         <li className={isPostsDropdownOpen ? "open" : ""}>
//           <a onClick={togglePostsDropdown}>
//             <img src='/images/write.png' width='18px' alt='Posts' />
//             <span className='sidebar-texts'>
//               Posts{" "}
//               <i
//                 className={`fa ${isPostsDropdownOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
//                 style={{ fontSize: "12px" }}
//               ></i>
//             </span>
//           </a>
//           <ul className={`dropdown-menu ${isPostsDropdownOpen ? "show" : ""}`}>
//             <li>
//               <NavLink to='/dashboard/manageposts' activeClassName='active'>
//                 Manage Posts
//               </NavLink>
//             </li>
//             <li>
//               <NavLink to='/dashboard/new-post' activeClassName='active'>
//                 New Post
//               </NavLink>
//             </li>
//           </ul>
//         </li>
//         <li className={isProductDropdownOpen ? "open" : ""}>
//           <a onClick={toggleProductDropdown}>
//             <img src='/images/product.png' width='18px' alt='Products' />
//             <span className='sidebar-texts'>
//               Products{" "}
//               <i
//                 className={`fa ${isProductDropdownOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
//                 style={{ fontSize: "12px" }}
//               ></i>
//             </span>
//           </a>
//           <ul className={`dropdown-menu ${isProductDropdownOpen ? "show" : ""}`}>
//             <li>
//               <NavLink to='/dashboard/manageproducts' activeClassName='active'>
//                 Manage Products
//               </NavLink>
//             </li>
//             <li>
//               <NavLink to='/dashboard/new-product' activeClassName='active'>
//                 New Product
//               </NavLink>
//             </li>
//           </ul>
//         </li>
//         <li>
//           <NavLink to='/dashboard/videos' activeClassName='active'>
//             <img src='/images/youtube.png' width='18px' alt='Youtube Videos' />
//             <span className='sidebar-texts'>Youtube Videos</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to='/dashboard/faqsmanager' activeClassName='active'>
//             <i className='fas fa-question-circle'></i>
//             <span className='sidebar-texts'>Faqs</span>
//           </NavLink>
//         </li>
//         <li className={isMailDropdownOpen ? "open" : ""}>
//           <a onClick={toggleMailDropdown}>
//             <i className='fas fa-envelope'></i>
//             <span className='sidebar-texts'>
//               Mail{" "}
//               <i
//                 className={`fa ${isMailDropdownOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
//                 style={{ fontSize: "12px" }}
//               ></i>
//             </span>
//           </a>
//           <ul className={`dropdown-menu ${isMailDropdownOpen ? "show" : ""}`}>
//             <li>
//               <NavLink to='/dashboard/subscribers' activeClassName='active'>
//                 Subscribers
//               </NavLink>
//             </li>
//             <li>
//               <NavLink to='/dashboard/inbox' activeClassName='active'>
//                 Enquiries
//               </NavLink>
//             </li>
//           </ul>
//         </li>
//         {isAdmin && (
//           <li>
//             <NavLink to='/dashboard/admins' activeClassName='active'>
//               <i className='fa fa-users'></i>
//               <span className='sidebar-texts'>Admin</span>
//             </NavLink>
//           </li>
//         )}
//         <li>
//           <NavLink to='/dashboard/waitlist' activeClassName='active'>
//             <i className='fa-solid fa-hourglass'></i>
//             <span className='sidebar-texts'>Waitlist</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to='/dashboard/comments' activeClassName='active'>
//             <img src='/images/comment.png' width='18px' alt='Comments' />
//             <span className='sidebar-texts'>Comments</span>
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to='/dashboard/trash' activeClassName='active'>
//             <i className='fa fa-trash removevid'></i>
//             <span className='sidebar-texts'>Trash</span>
//           </NavLink>
//         </li>
//       </ul>
//     </div>
//   );
// }

import React, { useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import "./sidebar.css";
import { FaEdit } from "react-icons/fa";
export default function Sidebar({ closeMenu }) {
  const { isAuthenticated } = useContext(AuthContext);
  const [isPostsDropdownOpen, setIsPostsDropdownOpen] = useState(false);
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);
  const [isMailDropdownOpen, setIsMailDropdownOpen] = useState(false);
  const { userDetails } = useContext(AuthContext);

  const togglePostsDropdown = () =>
    setIsPostsDropdownOpen(!isPostsDropdownOpen);
  const toggleProductDropdown = () =>
    setIsProductDropdownOpen(!isProductDropdownOpen);
  const toggleMailDropdown = () => setIsMailDropdownOpen(!isMailDropdownOpen);

  if (!isAuthenticated) {
    return null;
  }

  const isAdmin = userDetails.role === "Super-Admin";

  return (
    <div className="sidebar pt-5  fixed top-0 left-0 h-full w-64 bg-gray-50 text-[#274CB2] border-r border-[#625e5e31] p-4 transition-all ease-in-out duration-300 z-30 lg:pt-4">
      <a className="lg:hidden">
        <i
          className="absolute fa fa-xmark top-6 right-4 text-[22px] hover:cursor-pointer"
          onClick={closeMenu}
        ></i>
      </a>

      <img
        src="/images/achelis.png"
        alt="Logo"
        className="w-20 mb-16  lg:block"
      />
      <h3 className="text-sm text-[#274CB2] font-semibold mb-4 ml-3">
        ADMIN PANEL
      </h3>
      <ul className="space-y-2">
        <li className="mb-2">
          <NavLink
            to="/dashboard"
            exact
            activeClassName="bg-blue-500 text-[#274CB2]"
            onClick={closeMenu}
            className="flex items-center p-3 rounded text-[#274CB2] text-sm font-semibold hover:text-blue-600"
          >
            <i class="fa fa-tachometer-alt mr-2"></i>
            <span>Dashboard</span>
          </NavLink>
        </li>

        <li className={`mb-2 ${isPostsDropdownOpen ? "bg-none" : ""}`}>
          <button
            onClick={togglePostsDropdown}
            className="flex items-center p-3 rounded text-[#274CB2] text-sm font-semibold hover:text-blue-600 w-full text-left"
          >
            {/* <i className="fa fa-pencil-alt mr-2"></i> */}
            <FaEdit className="mr-2 text-1xl" />
            <span>
              Posts
              <i
                className={`fa ${
                  isPostsDropdownOpen ? "fa-chevron-up" : "fa-chevron-down"
                } ml-2 text-xs`}
              />
            </span>
          </button>

          <ul
            className={`ml-4 mt-2 space-y-1 ${
              isPostsDropdownOpen ? "block" : "hidden"
            }`}
          >
            <li>
              <NavLink
                to="/dashboard/manageposts"
                onClick={closeMenu}
                activeClassName="bg-blue-500 text-[#274CB2]"
                className="block p-2 rounded text-[#274CB2] text-sm font-semibold hover:text-blue-600"
              >
                Manage Posts
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/new-post"
                onClick={closeMenu}
                activeClassName="bg-blue-500 text-[#274CB2]"
                className="block p-2 rounded text-[#274CB2] text-sm font-semibold hover:text-blue-600"
              >
                New Post
              </NavLink>
            </li>
          </ul>
        </li>
       
       {isAdmin && (
        <>
        
        <li className="mb-2">
          <NavLink
            to="/dashboard/videos"
            exact
            onClick={closeMenu}
            activeClassName="bg-blue-500 text-[#274CB2]"
            className="flex items-center p-3 rounded text-[#274CB2] text-sm font-semibold hover:text-blue-600"
          >
            <i class="fa-brands fa-youtube mr-2"></i>
            <span>Youtube Videos</span>
          </NavLink>
        </li>
        <li className="mb-2">
          <NavLink
            to="/dashboard/faqsmanager"
            exact
            onClick={closeMenu}
            activeClassName="bg-blue-500 text-[#274CB2]"
            className="flex items-center p-3 rounded text-[#274CB2] text-sm font-semibold hover:text-blue-600"
          >
            <i className="fas fa-question-circle mr-2" />
            <span>Faqs</span>
          </NavLink>
        </li>
        
        <li className={`mb-2 ${isMailDropdownOpen ? "bg-none" : ""}`}>
          <button
            onClick={toggleMailDropdown}
            className="flex items-center p-3 rounded text-[#274CB2] text-sm font-semibold hover:text-blue-600 w-full text-left"
          >
            <i className="fas fa-envelope mr-2" />
            <span>
              Mail
              <i
                className={`fa ${
                  isMailDropdownOpen ? "fa-chevron-up" : "fa-chevron-down"
                } ml-2 text-xs`}
              />
            </span>
          </button>
          <ul
            className={`ml-4 mt-2 space-y-1 ${
              isMailDropdownOpen ? "block" : "hidden"
            }`}
          >
            <li>
              <NavLink
                to="/dashboard/subscribers"
                onClick={closeMenu}
                activeClassName="bg-blue-500 text-[#274CB2]"
                className="block p-2 rounded text-[#274CB2] text-sm font-semibold hover:text-blue-600"
              >
                Subscribers
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/inbox"
                onClick={closeMenu}
                activeClassName="bg-blue-500 text-[#274CB2]"
                className="block p-2 rounded text-[#274CB2] text-sm font-semibold hover:text-blue-600"
              >
                Enquiries
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/send-mail"
                onClick={closeMenu}
                state={{editable : true , url:"https://api.achillesdrill.com/letter/mail" , from:'/dashboard/'}}
                activeClassName="bg-blue-500 text-[#274CB2]"
                className="block p-2 rounded text-[#274CB2] text-sm font-semibold hover:text-blue-600"
              >
                Mailer
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/outbox"
                onClick={closeMenu}
                activeClassName="bg-blue-500 text-[#274CB2]"
                className="block p-2 rounded text-[#274CB2] text-sm font-semibold hover:text-blue-600"
              >
                Outbox
              </NavLink>
            </li>
          </ul>
        </li>
      </>
      )
}
        {isAdmin && (
          <>
          <li className="mb-2">
            <NavLink
              to="/dashboard/admins"
              exact
              onClick={closeMenu}
              activeClassName="bg-blue-500 text-[#274CB2]"
              className="flex items-center p-3 rounded text-[#274CB2] text-sm font-semibold hover:text-blue-600"
            >
              <i className="fa fa-users mr-2" />
              <span>Admin</span>
            </NavLink>
          </li>
       
        <li className="mb-2">
          <NavLink
            to="/dashboard/waitlist"
            exact
            onClick={closeMenu}
            activeClassName="bg-blue-500 text-[#274CB2]"
            className="flex items-center p-3 rounded text-[#274CB2] text-sm font-semibold hover:text-blue-600"
          >
            <i className="fa-solid fa-hourglass mr-2" />
            <span>Waitlist</span>
          </NavLink>
        </li>
       </>

)}
        <li className="mb-2">
          <NavLink
            to="/dashboard/comments"
            exact
            onClick={closeMenu}
            activeClassName="bg-blue-500 text-[#274CB2]"
            className="flex items-center p-3 rounded text-[#274CB2] text-sm font-semibold hover:text-blue-600"
          >
            <i class="fa fa-comments mr-2"></i>

            <span>Comments</span>
          </NavLink>
        </li>
       
      </ul>
    </div>
  );
}
