import React from "react";
import "./question.css";

const AccordionItem = ({ text, answer, id }) => {
  const idBody = {
    id: id,
  };

  function handleDelete() {
    fetch("https://api.achillesdrill.com/faq/delete", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(idBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        window.location.reload(); // Reload the page on successful deletion
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <tr>
        <td>{text}</td>
        <td>{answer}</td>
        <td>
          <span
            className="edit text-[#274CB2] text-2xl flex flex-col"
            onClick={handleDelete}
          >
            <i className="fa fa-trash removevid mx-auto transform transition-transform duration-300 ease-in-out hover:-translate-y-2"></i>
          </span>
        </td>
      </tr>
    </>
  );
};

export default AccordionItem;
