// import React, { useState, useEffect } from "react";
// import BlogItem from "./blogitem";
// import Spinner from "./loadingspinner";
// import Pagination from "./Pagination";

// export default function BlogList() {
//   const [blogs, setBlogs] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const entriesPerPage = 6;
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };
//   const indexOfLastEntry = currentPage * entriesPerPage;
//   const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
//   const currentEntries = blogs.slice(indexOfFirstEntry, indexOfLastEntry);
//   useEffect(() => {
//     fetch("https://api.achillesdrill.com/blog/fetch")
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         setBlogs(data.articles);
//         setLoading(false);
//       })
//       .catch((error) => {
//         setError(error);
//         setLoading(false);
//       });
//   }, []);

//   if (loading) {
//     return <Spinner />;
//   }

//   if (error) {
//     return <p>Error: {error.message}</p>;
//   }

//   return (
//     <>
//       <table className='data-table'>
//         <thead>
//           <tr>
//             <th>Image</th>
//             <th>Title</th>
//             <th>Comments</th>
//             <th>Views</th>
//             <th>Author</th>
//             <th>Category</th>
//             <th>Date Posted</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {currentEntries.map((item) => {
//             return (
//               <BlogItem
//                 key={item.id}
//                 imageurl={item.imageurl}
//                 title={item.title}
//                 author={item.author}
//                 category={item.category}
//                 comments={item.comments}
//                 views={item.views}
//                 datePosted={item.DatePosted}
//               />
//             );
//           })}
//         </tbody>
//       </table>
//       <Pagination
//         currentPage={currentPage}
//         totalEntries={blogs.length}
//         entriesPerPage={entriesPerPage}
//         onPageChange={handlePageChange}
//       />
//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import BlogItem from "./blogitem";
import Spinner from "./loadingspinner";
import Pagination from "./Pagination";

export default function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 6;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = blogs.slice(indexOfFirstEntry, indexOfLastEntry);

  useEffect(() => {
    fetch("https://api.achillesdrill.com/blog/fetch")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setBlogs(data.articles);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      <div className="overflow-x-auto mt-4">
        <table className="data-table">
          <thead>
            <tr className="bg-[#274CB2] text-white text-center text-sm">
              <th className="py-2 px-2">Image</th>
              <th className="py-2 px-2">Title</th>
              <th className="py-2 px-2">Comments</th>
              
              {/* <th className="py-2 px-2">Author</th> */}
              <th className="py-2 px-2">Category</th>
              <th className="py-2 px-2">Date Posted</th>
              <th className="py-2 px-2">Actions</th>
            </tr>
          </thead>

          <tbody className="text-center">
            {currentEntries.map((item) => (
              <BlogItem
                key={item._id}
                imageurl={item.image}
                title={item.title}
                
                category={item.category}
                comments={item.comments}

                id={item._id}
                datePosted={item.created_at}
              />
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={currentPage}
        totalEntries={blogs.length}
        entriesPerPage={entriesPerPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}
