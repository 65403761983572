import React, { useState, useEffect } from "react";

import Spinner from "./loadingspinner";

import Subscriberlist from "./subscriberlist";
import PageInfo from "./PageInfo";

export default function SubscribersPage() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch("https://api.achillesdrill.com/newsletter/subscribers")
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then(data => {
                setData(data.subscribers);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return(
<>
<PageInfo title ="Newsletter Subscribers List" active="Subscribers" from="Mail" />

        <Subscriberlist data={data}/>    
        
</>
    );
}