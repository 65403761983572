import { formatDistanceToNow } from "date-fns";
import React, { useState } from "react";
import { FaCheck } from "react-icons/fa6";
import Modal from "./Modal";
import { FaEdit, FaTimes } from "react-icons/fa";
export default function CommentItem(props) {
    const [comment  , setComment]=  useState(props.comment)
    const [editLoading , seteditLoading] = useState(false)
    const handleInputChange = (e) =>{
        setComment(e.target.value)
    }
    const formatRelativeTime = (date) => {
        const parsedDate = new Date(date);
      
        if (isNaN(parsedDate.getTime())) {
          throw new RangeError("Invalid time value");
        }
        return formatDistanceToNow(new Date(date), { addSuffix: true });
      };
      const [reload , setReload] = useState(false)
      const [loading , setloading] = useState(false)
  const [displayModal,setDisplaymodal] = useState(false)
  const closeModal = () =>{
    setDisplaymodal(false)
    window.location.reload(); // Reload the page on successful deletion

  }
  const [isSuccess, setIsSucces] = useState(true)
  const [responseMessage, setResponseMessage] = useState('')
  const [displayEditModal , setDisplayEditmodal] = useState(false)
  const openEditmodal = () =>{
    setDisplayEditmodal(!displayEditModal)
    console.log(comment)
  }
  const closeEditmodal = () =>{
    setDisplayEditmodal(false)
   setComment(props.comment)
  }
  
  const handleReload = () =>{
    setReload(!reload)
  }
   const approveComment = (id) => {
   setloading(true)
    const body = {
      "_id": id,
    };
  
    fetch("https://api.achillesdrill.com/comment/approve", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        // Reload the page on successful deletion
          
        } else if (response.status === 500) {
          throw new Error("Internal Server Error");
        }
      })
      .then((data) => {
        setResponseMessage(data.message)
        setIsSucces(true);
        setloading(false)
        setDisplaymodal(true)
      
// Reload the page on successful deletion
      })
      .catch((error) => {
        setResponseMessage(error.message)
        setIsSucces(false);
        setloading(false)
        setDisplaymodal(true)
        console.error( error.message);
      });
  };
  const editComment = (id , newcomment) => {
    seteditLoading(true)
     const body = {
       _id: id,
       comment : newcomment
     };
   
     fetch("https://api.achillesdrill.com/comment/edit", {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify(body),
     })
       .then((response) => {
         if (response.status === 200) {
            seteditLoading(false)
            setDisplayEditmodal(false)
           
           window.location.reload();
           return response.json();
         // Reload the page on successful deletion
           
         } else if (response.status === 500) {

           throw new Error("Internal Server Error");
           alert("Internal Server Error")
           seteditLoading(false)

         }
       })
       .then((data) => {
         
       return(data.message)
 // Reload the page on successful deletion
       })
       .catch((error) => {
       
         console.error( error.message);
       });
   };
    return(
       
      <tr className="text-center p-1">
           
            <td>{props.name}</td>
          
            <td>{props.comment}</td>
            <td>{props.status ? 'approved' : 'Not Approved'}</td> 
            <td>{props.post}</td> 
            <td>{formatRelativeTime(props.postingdate)}</td> 
            <td >
           { 
          props.status ?  " " 
           : ( <button   disabled={loading} 
      className="bg-[#274CB2] px-3 py-2 mt-2 text-white text-sm rounded-md border-[#274CB2] mr-2" 
      onClick={() => approveComment(props.commentid)}>  
       {loading ? <i className="fa fa-spinner fa-spin"></i> : <FaCheck />} 
            
            </button>)
}
            <button className="bg-[#274CB2]  hover:opacity-80 px-3 py-2 mt-2 text-white text-sm rounded-md border-[#274CB2] mr-2" 
            onClick={openEditmodal}
            >
                <FaEdit />
            </button>
            </td>
            {displayModal && (
        <Modal
          closeModal={closeModal} 
          isSuccess={isSuccess} 
          responseMessage={responseMessage} 
        />

      )}

      {
        displayEditModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-20">
        <div className='bg-white p-10 rounded-md w-[80%] mx-auto md:w-[70%] lg:w-[40%] relative'>
                 <h2>Edit Comment</h2>
                 <button className='absolute top-4 right-2 text-black-600 ' onClick={closeEditmodal}>
             <FaTimes />
            </button>
                 <div className="w-full mt-2"> 
                    <textarea value={comment} onChange={(e) => handleInputChange(e)} className="w-full border border-[#000000] rounded-md p-1"/>
                    <div className="flex mt-1">
                        <button className="bg-[#274CB2]  hover:opacity-80 px-5 py-2 mt-2 text-white text-sm rounded-md border-[#274CB2] mr-2" 
                         disabled={editLoading}
                         onClick={() => editComment(props.commentid , comment)}
                        >
                          {editLoading ? <i className="fa fa-spinner fa-spin"></i> : "Edit"}
                        </button>
                        <button className="bg-white  hover:opacity-80 px-5 py-2 mt-2 text-black-400 text-sm rounded-md border border-[#274CB2] " onClick={closeEditmodal}>
                            Close
                        </button>
                    </div>
               </div>

               </div>
                </div>
               
        )
      }
        </tr>
       
    
    )
}