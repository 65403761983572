import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "./loadingspinner";
import PageInfo from "./PageInfo";
import Quill from "quill";
import 'quill/dist/quill.snow.css';

export default function Message(props) {
  const { messageId } = useParams();
  const navigate = useNavigate();
  const [editorContent, setEditorContent] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const[sendloading, setsendloading] = useState(false)
  const [error, setError] = useState(null);
  const [mailTitle, setMailTitle] = useState('');
  const [mailBody, setMailBody] = useState('');
  const editorRef = useRef(null);
  const quillRef = useRef(null);

  useEffect(() => {
    fetch("https://api.achillesdrill.com/contact/enquiries")
      .then(response => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then(data => {
        const messages = data.enquiries;
        const message = messages.find(item => item._id === messageId);
        setData(message);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [messageId]);

  useEffect(() => {
    if (editorRef.current && !quillRef.current) {
      const quill = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
          ]
        }
      });
      quillRef.current = quill;

      quill.on('text-change', () => {
        setMailBody(quill.root.innerHTML);
      });

      const handleKeyDown = (e) => {
        if (e.key === 'Enter' && quill.getText().trim() === '') {
          quill.clipboard.dangerouslyPasteHTML(quill.getSelection().index, '<p><br></p>');
          e.preventDefault();
        }
      };

      quill.root.addEventListener('keydown', handleKeyDown);

      return () => {
        quill.root.removeEventListener('keydown', handleKeyDown);
        quillRef.current = null;
      };
    }
  }, [editorRef.current]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setsendloading(true)
    const mailData = {
      _id: messageId,
      fullname: `${data.fname} ${data.lname}`,
      email: data.email,
      subject: mailTitle,
      html: mailBody
    };

    fetch("https://api.achillesdrill.com/contact/outbox", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(mailData)
    })
    .then(response => {
      if (response.status === 200) {
        console.log("Mail sent successfully!");
        setsendloading(false)
        navigate('/dashboard/inbox');
      } else {
        setsendloading(false)

        throw new Error("Failed to send mail");
      }
    })
    .catch(error => {
      setsendloading(false)

      console.error("Error sending mail:", error);
    });
  };

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <PageInfo title="Reply Message" active="Reply Message" from="Enquires" parentpath="/dashboard/inbox" />
      <div className="mx-auto border p-2 rounded-md shadow-md w-full lg:w-3/4">
        <h3 className="font-semibold text-1xl">Message</h3>
        <div className="p-5 mt-2 border">
          <p>{data.enquiry}</p>
        </div>
        <h2 className="p-2 text-center font-semibold text-1xl">Reply to {`${data.fname}`}`s enquiry</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              value={mailTitle}
              onChange={(e) => setMailTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="body">Body</label>
            <div className="border rounded-md">
              <div ref={editorRef} className="editor"></div>
            </div>
          </div>
          <div className="form-actions">
            <button type="submit"disabled={sendloading} className="btn btn-primary">{sendloading ? <i className="fa fa-spinner fa-spin"></i>:"Send"}</button>
            <button type="button" className="btn btn-secondary" onClick={() => navigate('/dashboard/subscribers')}>Cancel</button>
          </div>
        </form>
      </div>
    </>
  );
}
