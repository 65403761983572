
import React, { useContext, useState } from "react"
import PageInfo from "./PageInfo";
import Modal from "./Modal";
import { AuthContext } from "./AuthContext";

 export  default function AddAdmin(params) {

    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [newAdmin, setNewAdmin] = useState({
        role:"Editor",
            email: '',
            password:"",
            fullname:"",
       
      });
      const { userDetails } = useContext(AuthContext);
      const isAdmin = userDetails.role === "Super-Admin"? true : false;
      if (!isAdmin){
          return ("Restricted")
      }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAdmin({ ...newAdmin, [name]: value });
      };
      const closeModal = () => {
        setShowModal(false);
      };
    
      const handleAddAdmin = async (e) => {
        e.preventDefault();
        setLoading(true);
      
        try {
          const response = await fetch("https://api.achillesdrill.com/admin/add", {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newAdmin),
          });
      
          if (response.status === 200) {
            const data = await response.json();
            setResponseMessage(data.message || "Admin added successfully!");
            setIsSuccess(true);
            setShowModal(true);
            setNewAdmin({
              role: "Editor",
              email: '',
              password: "",
              fullname: "",
            });
          } else if (response.status === 300) {
            const data = await response.json();
            setResponseMessage(data.message || "Request was redirected. Admin might not have been added.");
            setIsSuccess(false);
            setShowModal(true);
          } else {
            setResponseMessage("An error occurred. Please try again.");
            setIsSuccess(false);
            setShowModal(true);
          }
        } catch (error) {
          console.log(error);
          setResponseMessage("An error occurred. Please try again.");
          setIsSuccess(false);
          setShowModal(true);
        } finally {
          setLoading(false);
        }
      };
      
    
    return(
        <>
<PageInfo title ="Add New Admin" active="Add Admin" from="admins" parentpath="/dashboard/admins" />
<div className="flex justify-center min-h-full mt-5 mx-auto sm:max-w-[80%] md:mt-10">
        <div className="bg-white p-5 rounded-lg shadow-md w-full md:p-8 md:max-w-[70%] lg:max-w-[60%]">
          <h2 className="text-xl font-semibold mb-6">Add New Admin</h2>
          <form onSubmit={handleAddAdmin}>
          <input
               type="text"
               name="fullname"
               placeholder="Full Name"
               value={newAdmin.fullname}
               onChange={handleInputChange}
            className="text-sm w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            name="email"
            placeholder="Email"
            value={newAdmin.email}
            onChange={handleInputChange}
            className="text-sm w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
           type="text"
           name="password"
           placeholder="Password"
           value={newAdmin.password}
           onChange={handleInputChange}
            className="text-sm w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
         
             <select
  className="text-sm w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
  name="role"
  value={newAdmin.role}
  onChange={handleInputChange}
>
 
  <option value="Editor">Editor</option>
  <option value="Super-Admin">Super-Admin</option>
</select>

          <button
           disabled={loading}
            className="w-full bg-[#274CB2] text-white py-2 rounded hover:bg-blue-600 transition duration-200"
          >
            
            {loading ? <i className="fa fa-spinner fa-spin"></i> : <i className="fa fa-plus"></i>} Add Admin

          </button></form>
        </div>
      </div>
     

{showModal && (
        <Modal 
          closeModal={closeModal} 
          isSuccess={isSuccess} 
          responseMessage={responseMessage} 
        />
      )}
        
        </>
    )
}