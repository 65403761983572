import React, { useState } from "react";
import Modal from "./Modal"; // Import the Modal component
import PageInfo from "./PageInfo";

export default function AddFaq() {
  const [newFaqs, setNewFaqs] = useState({
    question: "",
    answer: "",
  });
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFaqs({ ...newFaqs, [name]: value });
  };

  const handleAddFaq = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch("https://api.achillesdrill.com/faq/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newFaqs),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(data.message || "FAQ added successfully!");
        setIsSuccess(true);
        setShowModal(true);
        setNewFaqs({
          question: "",
          answer: "",
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setResponseMessage("An error occurred. Please try again.");
        setIsSuccess(false);
        setShowModal(true);
        setLoading(false);
      });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <PageInfo
        title="Add New FAQ"
        active="Add Question"
        from="Faqs"
        parentpath="/dashboard/faqsmanager"
      />
     <div className="flex justify-center min-h-full mt-5 mx-auto sm:max-w-[80%] md:mt-10">
        <div className="bg-white p-5 rounded-lg shadow-md w-full md:p-8 md:max-w-[70%] lg:max-w-[60%]">
          <h2 className="text-xl font-semibold mb-6">Add New FAQ</h2>
          
          <form onSubmit={handleAddFaq}>
          
            <input
              type="text"
              name="question"
              placeholder="Question"
              value={newFaqs.question}
              onChange={handleInputChange}
              required
            className="text-sm w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"

            />
            <input
              type="text"
              name="answer"
              placeholder="Answer"
              value={newFaqs.answer}
              onChange={handleInputChange}
              required
            className="text-sm w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"

            />
            <button type="submit" disabled={loading} 
            className="w-full bg-[#274CB2] text-white py-2 rounded hover:bg-blue-600 transition duration-200"
            
            >
              {loading ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-plus"></i>
              )}{" "}
              Add Question
            </button>
          </form>
        </div>
      </div>
      {showModal && (
        <Modal
          closeModal={closeModal}
          isSuccess={isSuccess}
          responseMessage={responseMessage}
        />
      )}
    </>
  );
}
