import React, { createContext, useContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!sessionStorage.getItem('access_token'));
  const [userDetails, setUserDetails] = useState(sessionStorage.getItem('User_details'));

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (token) {
      fetchUserDetails(token);
    }
  }, []);

  const fetchUserDetails = async (token) => {
    const url = 'https://api.achillesdrill.com/admin/current';
    
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.status === 200) {
        const data = await response.json();
        console.log('Admin retrieved successfully:', data);
        sessionStorage.setItem('User_details', JSON.stringify(data.admin));
        setUserDetails(data.admin);
        setIsAuthenticated(true);
      } else if (response.status === 300) {
        setIsAuthenticated(false)
        console.error('Expired Access Token');
       
      } else if (response.status === 500) {
        setIsAuthenticated(false)

        console.error('Invalid Access Token');
      } else {
        setIsAuthenticated(false)

        console.error('Unexpected error:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const login = (token) => {
    sessionStorage.setItem('access_token', token);
    fetchUserDetails(token);
    console.log('User logged in, token set:', token);
  };

  const logout = () => {
    setIsAuthenticated(false);
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('User_details');
    setUserDetails(null);
    console.log('User logged out, token removed');
  };

  console.log('isAuthenticated:', isAuthenticated);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, userDetails }}>
      {children}
    </AuthContext.Provider>
  );
};
