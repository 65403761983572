import React, { useState, useEffect } from "react";

import Spinner from "./loadingspinner";

import Pagination from "./Pagination";
import WaitlistItem from "./waitlistitem";
import { Link, useNavigate } from "react-router-dom";

export function Waitlist() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [sendOption, setSendOption] = useState("all");
  const [emailList, setEmailList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 6;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleOptionChange = (event) => {
    setSendOption(event.target.value);
  };

  const handleClick = (e) => {
    setEmailList((prevList) => {
      return [...prevList, e.target.name];
    });
  };
  const handleSendMailClick = () => {
    navigate("/dashboard/send-mail");
  };

  useEffect(() => {
    fetch("https://api.achillesdrill.com/waitlist/fetch")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.waitlist);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = data.slice(indexOfFirstEntry, indexOfLastEntry);
  const allEmails = data.map((item) => item.email)
  const emailObject = {emaillist : sendOption === "all"? allEmails : emailList , url:"https://api.achillesdrill.com/waitlist/mailbox", from:"/dashboard/waitlist"}
  return (
    <>
    
      <table className="data-table">
        <thead>
          <tr>
            

            <th className="bg-[#274CB2]">S/N</th>
            <th className="bg-[#274CB2]">Name</th>
            <th className="bg-[#274CB2]">Email</th>
          </tr>
        </thead>
        <tbody>
          {currentEntries.map((item) => (
            <tr key={item._id} >
             
              <td className="text-center p-1">{data.indexOf(item) + 1}</td>
              <td>{item.name}</td>
              <td>{item.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="bulk-actions">
        <h3>BULK ACTIONS</h3>
        <div className="space-x-6 my-5 flex items-center">
          <label className="bg-[#274CB2] text-white py-2 px-4 rounded-lg hover:cursor-pointer">
            <input
              type="radio"
              value="all"
              checked={sendOption === "all"}
              onChange={handleOptionChange}
            />
            <span className="text-[#274CB2]">__</span>Send to All
          </label>
         
  <Link to="/dashboard/send-mail" state={emailObject}>
  <button
            onClick={handleSendMailClick}
            className="bg-[#274CB2] bg-opacity-5 px-4 py-2 text-gray-900 rounded-md shadow hover:cursor-pointer"
          >
            Send Mail
          </button> </Link>
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalEntries={data.length}
        entriesPerPage={entriesPerPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}
