import React, { useState } from "react";
import { FaTrash, FaTrashAlt } from "react-icons/fa";
import Modal from "./Modal";
export default function BlogItem(props) {
  const [reload , setReload] = useState(false)
  const [loading , setloading] = useState(false)
  const [displayModal,setDisplaymodal] = useState(false)
  const closeModal = () =>{
    setDisplaymodal(false)
    window.location.reload(); // Reload the page on successful deletion

  }
  const [isSuccess, setIsSucces] = useState(true)
  const [responseMessage, setResponseMessage] = useState('')
  const handleReload = () =>{
    setReload(!reload)
  }
   const removeBlog = (id) => {
   setloading(true)
    const body = {
      "_id": id,
    };
  
    fetch("https://api.achillesdrill.com/blog/delete", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        // Reload the page on successful deletion
          
        } else if (response.status === 500) {
          throw new Error("Internal Server Error");
        }
      })
      .then((data) => {
        setResponseMessage(data.message)
        setIsSucces(true);
        setloading(false)
        setDisplaymodal(true)
      
// Reload the page on successful deletion
      })
      .catch((error) => {
        setResponseMessage(error.message)
        setIsSucces(false);
        setloading(false)
        setDisplaymodal(true)
        console.error( error.message);
      });
  };
  const isoDate = props.datePosted;

// Create a Date object from the ISO string
const date = new Date(isoDate);

// Get the day, month, and year
const day = String(date.getUTCDate()).padStart(2, '0');
const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
const year = date.getUTCFullYear();

// Format the date as "dd/mm/yyyy"
const formattedDate = `${day}/${month}/${year}`;
  return (
    <tr>
      <td>
        <img src={props.imageurl} alt='' className='w-14 mx-auto'></img>
      </td>
      <td>{props.title}</td>
      <td>{props.comments.length}</td>
      {/* <td>{props.views}</td> */}
      {/* <td>{props.author}</td> */}
      <td>{props.category}</td>
      <td>{formattedDate}</td> 
      <td>
      <button 
       disabled={loading} 
      className="bg-[#274CB2] px-3 py-2 mt-2 text-white text-sm rounded-md border-[#274CB2] mr-2" 
      onClick={() => removeBlog(props.id)}>  
       {loading ? <i className="fa fa-spinner fa-spin"></i> : <FaTrashAlt />} 
      </button>
      </td>
      {displayModal && (
        <Modal
          closeModal={closeModal} 
          isSuccess={isSuccess} 
          responseMessage={responseMessage} 
        />
      )}
    </tr>
  );
}

// import React from "react";

// export default function BlogItem(props) {
//   return (
//     <tr>
//       <td className='px-6 py-4 whitespace-nowrap'>
//         <img
//           src={`/images/${props.imageurl}`}
//           alt=''
//           className='w-10 h-10 object-cover rounded-full'
//         />
//       </td>
//       <td className='px-6 py-4 whitespace-nowrap'>{props.title}</td>
//       <td className='px-6 py-4 whitespace-nowrap'>{props.comments}</td>
//       <td className='px-6 py-4 whitespace-nowrap'>{props.views}</td>
//       <td className='px-6 py-4 whitespace-nowrap'>{props.author}</td>
//       <td className='px-6 py-4 whitespace-nowrap'>{props.category}</td>
//       <td className='px-6 py-4 whitespace-nowrap'>{props.datePosted}</td>
//       <td className='px-6 py-4 whitespace-nowrap flex space-x-4'>
//         <span className='edit flex items-center text-blue-500 cursor-pointer'>
//           <img src='/images/write.png' width='10px' height='13px' className='mr-1' alt='edit' />
//           Edit
//         </span>
//         <span className='edit flex items-center text-red-500 cursor-pointer'>
//           <i className='fa fa-trash removevid mr-1'></i>Trash
//         </span>
//       </td>
//     </tr>
//   );
// }
