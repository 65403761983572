// App.js
import React, { useContext, useEffect } from "react";
import "./sidebar.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./dashboard";
import Login from "./login";
import Blogmanager from "./blogmanager";
import Videos from "./Videospage";
import AddVideo from "./addnewvideo";
import ManageProducts from "./manageproducts";
import AddProduct from "./addnewproduct";
import ManagePosts from "./manageposts";
import FaqsManager from "./faqsmanager";
import Inboxpage from "./inboxpage";
import AdminPage from "./adminpage";
import CommentPage from "./commentspage";
import Message from "./viewmessage";
import SubscribersPage from "./subscriberspage";
import Layout from "./Layout";
import { AuthContext } from "./AuthContext";

import SendMailPage from "./sendMailPage.js";
import AddFaq from "./addquestion.js";
import AddAdmin from "./addAdmin.js";
import { Waitlist } from "./Waitlist.js";
import OutboxPage from "./outboxpage.js";

function App() {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Login />} />

        <Route path='/login' element={<Login />} />
        <Route path='/dashboard' element={<Layout />}>
          <Route
            index
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <Dashboard />
              </PrivateRoute>
            }
          />

          <Route
            path='new-post'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <Blogmanager />
              </PrivateRoute>
            }
          />
          <Route
            path='videos'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <Videos />
              </PrivateRoute>
            }
          />
          <Route
            path='send-mail'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <SendMailPage />
              </PrivateRoute>
            }
          />

          <Route
            path='faqsmanager/new'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <AddFaq />
              </PrivateRoute>
            }
          />
          <Route
            path='videos/newvideo'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <AddVideo />
              </PrivateRoute>
            }
          />
          {/* <Route
            path='manageproducts'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <ManageProducts />
              </PrivateRoute>
            }
          />
          <Route
            path='new-product'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <AddProduct />
              </PrivateRoute>
            }
          /> */}
          <Route
            path='manageposts'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <ManagePosts />
              </PrivateRoute>
            }
          />
          <Route
            path='faqsmanager'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <FaqsManager />
              </PrivateRoute>
            }
          />
          <Route
            path='inbox'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <Inboxpage />
              </PrivateRoute>
            }
          />
          <Route
            path='admins'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <AdminPage />
              </PrivateRoute>
            }
          />
          <Route
            path='admins/add'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <AddAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path='comments'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <CommentPage />
              </PrivateRoute>
            }
          />
          <Route
            path='message/:messageId'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <Message />
              </PrivateRoute>
            }
          />
          <Route
            path='subscribers'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <SubscribersPage />
              </PrivateRoute>
            }
          />
          <Route
            path='waitlist'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <Waitlist />
              </PrivateRoute>
            }
          />
            <Route
            path='outbox'
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <OutboxPage />
              </PrivateRoute>
            }
          />

        </Route>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
