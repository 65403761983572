import React, { useState, useEffect } from "react";
import AccordionItem from "./question";
import "./question.css";
import Spinner from "./loadingspinner";

export default function QuestionList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("https://api.achillesdrill.com/faq/read")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.faq);
        console.log(data.faq);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <table className="data-table">
      <thead>
        <tr>
          <th className="bg-[#274CB2]">Question</th>
          <th className="bg-[#274CB2]">Answer</th>
          <th className="bg-[#274CB2]">Trash</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <AccordionItem
            key={item.id}
            text={item.question}
            answer={item.answer}
            id={item._id}
          />
        ))}
      </tbody>
    </table>
  );
}
